<template>
  <div
    class="widgetContainer bg-white widgetContainer--scrollable pullFundsAmount">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="backAction" />
        <p class="title">
          {{ $t('pull_fund_row_title') }}
        </p>
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form class="wise-form" ref="pullFundsAmount" :model="formData">
        <el-form-item
          prop="selectedBank"
          class="is-no-asterisk"
          :label="$t('pay_section_source')">
          <el-select v-model="formData.selectedBank" style="width: 100%">
            <el-option
              v-for="bank in getSelfContacts"
              :key="bank.id"
              :label="computedTitle(bank)"
              :value="bank.id" />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="selectedBank"
          class="is-no-asterisk"
          :label="$t('pay_section_destination')">
          <el-input :value="getSelectedAccount.label" />
        </el-form-item>

        <el-form-item
          class="is-no-asterisk"
          prop="amount"
          :label="$t('pay_row_amount')">
          <Amount :masked="true" v-model="formData.amount" />
        </el-form-item>

        <el-form-item
          class="is-no-asterisk line-height-adjust"
          prop="description"
          label="Same Day">
          <el-checkbox ref="isSameDay" />
        </el-form-item>

        <el-form-item
          prop="selectedBank"
          class="is-no-asterisk"
          :label="$t('description')">
          <el-input v-model="formData.description" />
        </el-form-item>
      </el-form>
    </div>
    <div class="el-drawer__footer">
      <el-button
        type="primary"
        :disabled="isFormValid"
        @click="sendTransfer"
        class="el-button__brand brand width-100">
        {{ $t('transfer') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import Amount from '@/components/Amount';
import { mapActions, mapGetters } from 'vuex';
import { mask } from '@/utils/general';

export default {
  components: {
    Amount
  },
  data() {
    return {
      formData: {
        selectedBank: null,
        destination: '',
        amount: '',
        description: ''
      }
    };
  },
  computed: {
    ...mapGetters('contact', ['getSelfContacts']),
    ...mapGetters('fund', ['getPullFundDetails']),
    ...mapGetters('account', ['getSelectedAccount']),
    isFormValid() {
      return !(
        !!this.formData.selectedBank &&
        !!this.getSelectedAccount &&
        !!this.formData.amount &&
        !!this.formData.description
      );
    }
  },
  created() {
    if (this.getPullFundDetails && this.getPullFundDetails.contact) {
      this.formData.selectedBank = this.getPullFundDetails.contact.id;
    }
  },
  methods: {
    ...mapActions('fund', ['receiveAch']),
    backAction() {
      this.drawerBack();
    },
    computedTitle(b) {
      return b.ach.bankName + ' ' + mask(b.ach.accountNumber);
    },
    sendTransfer() {

      const loader = this.showLoader();
      this.receiveAch({
        accountId: this.getSelectedAccount.id,
        amount: '' + this.formData.amount,
        contactId: this.formData.selectedBank,
        description: this.formData.description,
        type: this.$refs.isSameDay.isChecked ? 'sameDay' : 'nextDay'
      })
        .then(() => {
          this.drawerPush('pullFundsSuccess');
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    }
  }
};
</script>

<style lang="scss">
.pullFundsAmount {
  position: relative;

  .el-button__brand {
    text-transform: capitalize;
  }

  .el-form-item.line-height-adjust{
    .el-form-item__content{
      line-height: 15px;
    }
  }
}
</style>
